import { useCallback } from "react";
import { Box, Typography } from "@mui/material";

import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import { WithdrawalFormDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";

function PurchasePriceSummary({
  companyType,
  withdrawalDataList,
}: {
  companyType: PartnerBusinessArea;
  withdrawalDataList: WithdrawalFormDetail[];
}) {
  const getTotalPrice = useCallback(
    (type: "totalPrice" | "vatPrice" | "finalPrice") => {
      return withdrawalDataList?.reduce((acc, cur) => {
        acc += cur[type];

        return acc;
      }, 0);
    },
    [withdrawalDataList]
  );

  /** 합계(외화) 값을 리턴 */
  const getTotalForeignCurrency = () => {
    return withdrawalDataList?.reduce((acc, cur) => {
      if (cur.currency && cur.currency !== "KRW") {
        acc += cur.amount * cur.unitPrice;
      } else {
        acc += 0;
      }

      return acc;
    }, 0);
  };

  return (
    <Box display="flex" justifyContent={"flex-end"} gap={2}>
      {companyType !== "foreign" && (
        <Box display="flex" gap={1}>
          <Typography variant="body1" component="div">
            공급가 합계:
          </Typography>

          <Typography variant="body1" component="div" textAlign="right">
            {toThousandUnitFormat(getTotalPrice("totalPrice"))}
          </Typography>
        </Box>
      )}

      {companyType !== "foreign" && (
        <Box display="flex" gap={1}>
          <Typography variant="body1" component="div">
            부가세 합계:
          </Typography>

          <Typography variant="body1" component="div" textAlign="right">
            {toThousandUnitFormat(getTotalPrice("vatPrice"))}
          </Typography>
        </Box>
      )}

      <Box display="flex" gap={1}>
        <Typography variant="body1" component="div">
          외화 합계:
        </Typography>

        <Typography variant="body1" component="div" textAlign="right">
          {toThousandUnitFormat(getTotalForeignCurrency())}
        </Typography>
      </Box>

      <Box display="flex" gap={1}>
        <Typography variant="body1" component="div">
          총 합계:
        </Typography>

        <Typography variant="body1" component="div" textAlign="right">
          {toThousandUnitFormat(getTotalPrice("finalPrice"))}
        </Typography>
      </Box>
    </Box>
  );
}
export default PurchasePriceSummary;
